// src/core/util/reportWebVitals.ts
import type { Metric } from 'web-vitals';

/**
 * Envoie les métriques Web Vitals à l'API locale pour traitement et enregistrement.
 *
 * @param {Metric} metric - La métrique Web Vitals capturée.
 */
const reportWebVitals = (metric: Metric) => {
  // Affiche la métrique dans la console pour le débogage
  console.log(metric);

  // Envoie les métriques à l'API locale
  fetch('/api/web-vitals', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(metric),
  })
    .then((response) => response.json())
    .then((data) => console.log('Web Vitals sent successfully:', data))
    .catch((error) => console.error('Error sending Web Vitals:', error));
};

export default reportWebVitals;
