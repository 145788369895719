// src/components/BaseCard.jsx
'use client';
import styles from './BaseCard.module.css';
import dynamic from 'next/dynamic';

const CityLink = dynamic(() => import('@/server/link/CityLink'), {
  ssr: false,
});

const BaseCard = ({ BackgroundComponent, title, children, link }) => {
  return (
    <div className={styles.card}>
      {BackgroundComponent && <BackgroundComponent />}
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p>{children}</p>
        {link && (
          <CityLink href={link} passHref>
            <div className={styles.button}>
              <span>Visitez la page</span>
              <span>En savoir plus</span>
            </div>
          </CityLink>
        )}
      </div>
    </div>
  );
};

export default BaseCard;
