'use client';
import { useEffect } from 'react';

const Analytics = () => {
  useEffect(() => {
    const consent = localStorage.getItem('cookie-consent');
    if (consent === 'accepted') {
      // Initialiser les services nécessitant des cookies
      console.log("Chargement des scripts d'analyse.");
      // Par exemple, charger Google Analytics, Facebook Pixel, etc.
    }
  }, []);

  return null;
};

export default Analytics;
