// CardSection.js
'use client';

import React, { Suspense } from 'react';
import dynamic from 'next/dynamic';
import ReusableCard from '@/client/card/BaseCard';
import styles from './CoreServices.module.css';

const ParebriseImg = dynamic(() => import('@/img/services/Parebrise'), { ssr: false });
const LunnetteImg = dynamic(() => import('@/img/services/Lunette'), { ssr: false });
const DeflectorImg = dynamic(() => import('@/img/services/Deflector'), { ssr: false });
const LateralImg = dynamic(() => import('@/img/services/Lateral'), { ssr: false });

const ImagePlaceholder = () => (
  <div className={styles.imagePlaceholder}>
    <div>Loading...</div>
  </div>
);

const CardSection = () => {
  return (
    <div className={styles.cardContainer}>
      <Suspense fallback={<ImagePlaceholder />}>
        <ReusableCard BackgroundComponent={ParebriseImg} title='Rachats de voitures' link='/services/rachats-de-voitures'>
          ProRachatAuto.fr rachète votre voiture directement à {'name'} dans l&apos;heure. Prenez rendez-vous directement sur notre accueil téléphonique.
        </ReusableCard>
        <ReusableCard BackgroundComponent={LunnetteImg} title='Enlèvement voiture' link='/services/enlevements-de-vehicules'>
          Faites enlever votre voiture directement par ProRachatAuto.fr. Nous viendrons récupérer votre véhicule à son lieu de stationnement ou vers {'name'}.
        </ReusableCard>
        <ReusableCard BackgroundComponent={DeflectorImg} title='Jeter votre voiture' link='/services/jetez-votre-vehicule'>
          ProRachatAuto.fr vous propose de venir débarrasser votre véhicule à {'name'} à but écologique. Prenons soin de la planète tous ensemble.
        </ReusableCard>
        <ReusableCard BackgroundComponent={LateralImg} title='Dépannage Express' link='/services/depannage-express'>
          Dépannez n&apos;importe quel véhicule avec Pro-Rachats-Auto.fr à {'name'} et profitez de notre gamme de dépanneurs agréés.
        </ReusableCard>
      </Suspense>
    </div>
  );
};

export default CardSection;
